import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './hint-text.scss';

const block = 'hint-text';
const HintText = ({ hintText, type }) => {


  return (
    <div className={block}>
      <div className={cn(block, `${block}--wrapper`, { 
          [`${block}--blue`]: type === 'info',
          [`${block}--green`]: type === 'success' 
        } )}>
        {hintText}
      </div>
    </div>
  );
};

HintText.propTypes = {
  hintText: PropTypes.element,
  type: PropTypes.string
};

export default HintText;
