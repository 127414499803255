import React, { useContext, useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import { Table } from '../../components/Argo-ui';
import { formatCurrency } from '../../utils/currency';
// import Switcher from '../../components/switcher/switcher';
import HintText from '../../components/hint-text/hint-text';
// import TableNew from '../../components/table/table';
import MainFooter from '../../components/main-footer/main-footer';
import IconAlert from '../../images/exclamation-info.svg';
import iconReturn from '../../images/return.svg';
import {
  cancelEdit,
  changeValorParcela,
  isEditValid,
} from '../../helpers/editQuotation';
import { DataContext } from '../../data/DataProvider';
import { getCoberturasApolice } from '../../services/base-service';
import ExclamationIcon from '../../images/exclamation-circle.svg';
import './index.scss';
import DefaultLayout from '../../components/layouts/default-layout';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
const block = 'page-coberturas';
const Page = ({ location }) => {
  const {
    setModal,
    quotation,
    setQuotation,
    originalQuotation,
    setOriginalQuotation,
    responseMotor,
    today,
    limitDay,
  } = useContext(DataContext);
  const [responseCoberturas, setResponseCoberturas] = useState();
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const listCoberturas = await getCoberturasApolice(
        quotation?.dadosEmpresa?.atividade,
        quotation?.avaliacaoRisco?.valorRiscoTotal
          ? quotation.avaliacaoRisco.valorRiscoTotal
          : 0
      );
      setResponseCoberturas(listCoberturas);
    }
    fetchData();
  }, [quotation]);

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
    if (quotation && !quotation?.exposicaoPolitica)
      return navigate('/exposicao-politica');
  }, [quotation, quotation?.exposicaoPolitica]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };

  const onSubmit = () => {
    if (responseMotor.error) {
      trackCustomEvent({
        category: 'Formulário com dados inválidos',
        action: 'Submit',
        label: responseMotor.msgError,
      });
      setModal({
        values: {
          headline: 'Ops! ',
          message: responseMotor.msgError,
          primaryButton: {
            label: 'Ok',
            handler: () => {},
          },
        },
      });
    } else {
      if (!quotation?.propostaId) return navigate('/finalizar-proposta');
      return changeValorParcela(
        quotation,
        responseMotor.response,
        setModal,
        setDisableSave,
        setOriginalQuotation
      );
    }
  };

  const removeCurrency = (valor) => {
    const remove = valor?.toString().replace(/[^0-9]/g, '');
    return parseInt(remove);
  };

  const intialValues = {
    risco: quotation?.avaliacaoRisco?.valorRiscoTotal
      ? quotation.avaliacaoRisco.valorRiscoTotal
      : 0,
  };

  const inputChangeHandler = useCallback(
    (valorInput, id, limite) => {
      if (
        removeCurrency(valorInput) > 0 &&
        removeCurrency(valorInput) <= limite
      ) {
        setQuotation({
          ...quotation,
          coberturas: [
            ...quotation.coberturas.filter((filter) => filter.cobertura !== id),
            { cobertura: id, valor: removeCurrency(valorInput) },
          ],
          isProposal: false,
        });
        trackCustomEvent({
          category: 'Adiciona cobertura',
          action: 'KeyUp',
          label: id,
          value: removeCurrency(valorInput),
        });
        setValue(`cobertura_${id}`, formatCurrency(removeCurrency(valorInput)));
      } else {
        setQuotation({
          ...quotation,
          coberturas: [
            ...quotation.coberturas.filter((filter) => filter.cobertura !== id),
          ],
          isProposal: false,
        });
        trackCustomEvent({
          category: 'Remove cobertura',
          action: 'KeyUp',
          label: id,
        });
        setValue(`cobertura_${id}`, '');
      }
    },
    [quotation, setQuotation, setValue]
  );

  const inputRiscoChangeHandler = useCallback(
    (valorInput) => {
      trackCustomEvent({
        category: 'Valor total em risco',
        action: 'KeyUp',
        label: removeCurrency(valorInput),
      });
      if (
        removeCurrency(valorInput) > 0 &&
        removeCurrency(valorInput) <= 25000000
      ) {
        setQuotation({
          ...quotation,
          avaliacaoRisco: {
            ...quotation.avaliacaoRisco,
            valorRiscoTotal: removeCurrency(valorInput),
          },
          isProposal: false,
        });

        setValue('risco', formatCurrency(removeCurrency(valorInput)));
      } else {
        setValue('risco', '');
      }
    },
    [quotation, setQuotation, setValue]
  );

  const columnsExtras = [
    {
      title: 'Coberturas',
      dataIndex: 'coberturas',
      width: '50%',
    },
    {
      title: 'Franquia',
      dataIndex: 'franquia',
      width: '42%',
    },
    {
      title: 'LMI',
      dataIndex: 'lmi',
      width: '8%',
      render: (value) => {
        return (
          <fieldset className="fieldset">
            <input
              id={value[0]}
              defaultValue={value[1] && formatCurrency(parseFloat(value[1]))}
              {...register(`cobertura_${value[0]}`)}
              placeholder="Insira um valor"
              onKeyUp={(item) =>
                inputChangeHandler(item.target.value, value[0], value[2])
              }
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              className="input small"
            />

            {errors[`cobertura_${value[0]}`] ? (
              <p className="error" align="center">
                Limite excedido
              </p>
            ) : (
              <p className="helpText" align="center">
                {value[2]
                  ? `Máximo:  ${formatCurrency(value[2])}`
                  : `Sem limite`}
              </p>
            )}
          </fieldset>
        );
      },
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   width: '5%',
    //   render: (value) => (
    //     <Switcher status={value[0]} lock={value[1]}  />
    //   )
    // }
  ];

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block} id="scrollHint">
        <MainHeader />
        <PageHeader
          backButtonHandler={() => navigate('/exposicao-politica')}
          editMode={!!quotation?.propostaId}
          title="Coberturas disponíveis"
          description={
            <span>
              Você pode editar ou adicionar coberturas, que o prêmio será
              recalculado simultaneamente.
              <br />
              <a href="#hint" className={`${block}--link`}>
                <span>Consulte as regras de coberturas </span>
                <img
                  src={iconReturn}
                  alt="consultar regras"
                  className={`${block}--icon-return`}
                />
              </a>
            </span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
            {
              label: 'Avaliação de risco',
              path: '/avaliacao-de-risco',
            },
            {
              label: 'Sistemas protecionais',
              path: '/sistemas-protecionais',
            },
            {
              label: 'Sinistralidade',
              path: '/sinistralidade',
            },
            {
              label: 'Exposição política',
              path: '/exposicao-politica',
            },
            {
              label: 'Coberturas',
              path: '/coberturas',
            },
          ]}
          fixed
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`container--large ${block}--fixed`}>
            {/* <div className={`${block}__titleFormGroup`}>
                <span>Valor em risco total</span>
              </div> */}
            <div
              className={`${block}--wrapper-input`}
              style={limitDay >= today ? { width: '704px', top: '16px' } : null}
            >
              <fieldset className={`fieldset ${block}--input-risco`}>
                <label className="label large" htmlFor="risco">
                  Valor em risco total
                </label>
                <input
                  defaultValue={formatCurrency(
                    removeCurrency(intialValues.risco)
                  )}
                  placeholder="Limite Máx. 25.000.000"
                  onKeyUp={(val) => {
                    inputRiscoChangeHandler(val.target.value);
                  }}
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }
                  {...register('risco', {
                    required: true,
                    validate: (value) =>
                      parseInt(removeCurrency(value)) >= 20000,
                  })}
                  className={`input ${block}--input-risco`}
                />
                {errors.risco ? (
                  <p className="error">
                    Preenchimento incorreto{' '}
                    <img
                      alt="Erro"
                      className="iconError"
                      src={ExclamationIcon}
                    />
                  </p>
                ) : (
                  <p className="helpText">
                    De R$ 20.000 até{' '}
                    {quotation?.avaliacaoRisco?.valorRiscoMaximo
                      ? formatCurrency(
                          parseInt(quotation?.avaliacaoRisco?.valorRiscoMaximo)
                        )
                      : 'R$ 25.000.000'}
                  </p>
                )}
              </fieldset>
            </div>
            <div style={limitDay >= today ? { marginTop: '9rem' } : null}>
              <Table
                loading={false}
                columns={columnsExtras}
                dataSource={
                  responseCoberturas &&
                  responseCoberturas.length &&
                  responseCoberturas.map(
                    ({ coberturaId, franquia, limite, nome }) => {
                      const valor = quotation?.coberturas?.find(
                        ({ cobertura }) => cobertura === coberturaId
                      );
                      return {
                        rowKey: coberturaId,
                        coberturas: nome,
                        franquia: franquia,
                        lmi: [coberturaId, valor && valor.valor, limite, nome],
                        // status: [data.coberturas[coberturaId-1]?.valor ? true : false, obrigatoria]
                      };
                    }
                  )
                }
              />
            </div>
          </div>

          <div className={`container--large`} id="hint">
            {isEditValid(quotation) && (
              <div className="action_buttons">
                <Button
                  className="button_cta"
                  type="button"
                  theme={'secondary'}
                  onClick={cancel}
                  disabled={disableSave}
                >
                  Cancelar edição
                </Button>
                <Button
                  className={`button_cta button--margin-left`}
                  type="submit"
                  theme={'tertiary'}
                  disabled={disableSave}
                >
                  Salvar edição
                </Button>
              </div>
            )}
            {!quotation?.propostaId && (
              <Button type="submit" className="button_cta">
                Avançar
              </Button>
            )}
          </div>
        </form>
        <div className={`container--large`}>
          <HintText
            type={'info'}
            hintText={
              <a onClick={() => window.scrollTo(0, 0)} id="hint-text" href="/#">
                <div className={`row`}>
                  <div className={`col`}>
                    <img
                      src={IconAlert}
                      className={`icon`}
                      alt="Informação importante"
                    />
                  </div>
                  <div className={`col`}>
                    <p className={'txt-14'}>
                      <strong>
                        Regras obrigatórias para aceitação das coberturas:
                      </strong>
                    </p>
                    <ul className={`list`}>
                      <li>
                        É obrigatória a contratação da cobertura Básica
                        (Incêndio, Raio, Explosão) com pelo menos mais uma
                        cobertura adicional. Caso esta seja Lucros Cessantes,
                        será necessário incluir mais uma cobertura acessória.
                      </li>
                      <li>
                        A cobertura de "Responsabilidade Civil Operações - Danos
                        Morais" não pode ser superior a cobertura de "RC
                        Operações", bem como a cobertura de "Responsabilidade
                        Civil Empregador - Danos Morais" não pode ser superior a
                        "Responsabilidade Civil Empregador".
                      </li>
                      <li>
                        Não serão aceitas vigências retroativas a data de envio
                        do pedido de emissão a Cia.
                      </li>
                      <li>
                        A Seguradora poderá solicitar informações adicionais do
                        risco para confirmação de aceitação da proposta.
                      </li>
                      <li>
                        A seguradora possui o prazo de 15 dias para a aceitação
                        do risco, após o recebimento da proposta de seguro,
                        devidamente preenchida e assinada.
                      </li>
                      <li>
                        O segurado através desta proposta autoriza a emissão do
                        seguro conforme dados constantes na cotação e se
                        responsabiliza pelo pagamento do prêmio.
                      </li>
                    </ul>
                  </div>
                </div>
              </a>
            }
          />
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
